import firebase from 'firebase/app';
import 'firebase/auth';
import "firebase/firestore";

const configProd = {
  apiKey: "AIzaSyA4MezQFmg2N_ap3B7j7elNgECUoEN7IcE",
  authDomain: "mobilizei.firebaseapp.com",
  databaseURL: "https://mobilizei.firebaseio.com",
  projectId: "mobilizei",
  storageBucket: "mobilizei.appspot.com",
  messagingSenderId: "58249794297",
  appId: "1:58249794297:web:7518965398880a2b9f8828"
};

const configDev = {
  apiKey: "AIzaSyARmBuXf-tlt_gOFFCE28aMzkvmPlimLGs",
  authDomain: "mobilizei-development.firebaseapp.com",
  databaseURL: "https://mobilizei-development.firebaseio.com",
  projectId: "mobilizei-development",
  storageBucket: "mobilizei-development.appspot.com",
  messagingSenderId: "214620005771",
  appId: "1:214620005771:web:85023547b51ee64042bb8e"
};

class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(process.env.REACT_APP_ENV === 'prod' ? configProd : configDev);
    }

    this.auth = firebase.auth();
    this.db = firebase.firestore();
  }

  // *** Auth API ***

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)
      .then(() => {
        window.localStorage.setItem('email', email);
        window.localStorage.setItem('password', password);
      })

  doSignOut = () => this.auth.signOut().then(() => window.localStorage.clear());

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        authUser.getIdTokenResult()
          .then(idTokenResult => {
            // Add role to authUser
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              role: idTokenResult.claims.role
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

  getStores = (platformId) => {
    return this.stores(platformId).get()
      .then(snapshot => {
        if (snapshot.empty) {
          return [];
        }

        let stores = [];

        snapshot.forEach(doc => {
          stores.push({
            id: doc.id,
            ...doc.data()
          })
        });

        return stores;
      }).catch(() => {
        return [];
      })
  }

  updateStore = (platformId, storeId, data) => {
    return this.stores(platformId).doc(storeId).update(data);
  }

  getLeads = () => {
    return this.leads().get()
      .then(snapshot => {
        if (snapshot.empty) {
          return [];
        }

        let leads = [];

        snapshot.forEach(doc => {
          leads.push({
            id: doc.id,
            ...doc.data()
          })
        });

        return leads;
      }).catch((e) => {
        console.log('error on get leads', e)
        return [];
      })
  }

  deleteLead = (leadId) => {
    return this.leads().doc(leadId).delete();
  }

  getUsers = () => {
    return this.users().get()
      .then(snapshot => {
        if (snapshot.empty) {
          return [];
        }

        let users = [];

        snapshot.forEach(doc => {
          users.push({
            id: doc.id,
            ...doc.data()
          })
        });

        return users;
      }).catch((e) => {
        console.log('error on get users', e)
        return [];
      })
  }

  getPublications = () => {
    return this.publications().get()
      .then(snapshot => {
        if (snapshot.empty) {
          return [];
        }

        let publications = [];

        snapshot.forEach(doc => {
          publications.push({
            id: doc.id,
            ...doc.data()
          })
        });

        return publications;
      }).catch((e) => {
        console.log('error on get publications', e)
        return [];
      })
  }

  // *** User API ***

  // user = uid => this.db.collection("users").doc(uid)

  users = () => this.db.collection("users");

  stores = (platformId) => this.db.collection("platforms").doc(platformId).collection("stores");

  leads = () => this.db.collection("leads");

  publications = () => this.db.collection("publications").orderBy('createdAt', 'desc');
}

export default Firebase;