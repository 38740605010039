import React, { useEffect } from 'react';
import { Box, FormControl, Grid, InputLabel, Select, Typography } from '@material-ui/core';
import _ from 'lodash';

export default function Step1({ formData, handleChangeData, isFinished }) {

    useEffect(() => {
      if(
        !_.isEmpty(formData.selectedPlatform) &&
        _.isBoolean(formData.firstVersion) &&
        _.isBoolean(formData.exclusiveApp)
      ){
        isFinished(true)
      }else{
        isFinished(false)
      }
    }, [formData])

    const handleSelect = (e) => {
      let value = e.target.value;

      switch(e.target.value){
        case 'true':
          value = true;
          break;
        case 'false':
          value = false;
          break;
        default:
          break;
      }

      handleChangeData(e.target.name, value)
      
    }

    const storeNameByPlatform = formData.selectedPlatform 
      ? formData.selectedPlatform === 'android' 
        ? 'Google Play' 
        : 'App Store'
      : 'Loja de aplicativos'

    return (
        <Grid container direction='column'>

          <Box mt={2} mb={2}>
            <InputLabel>Plataforma</InputLabel>
            <FormControl fullWidth>
              <Select
                native
                name="selectedPlatform"
                value={formData.selectedPlatform}
                label="Plataforma"
                onChange={handleSelect}
                fullWidth
              >
                <option value="">Selecione</option>
                <option value='android'>Android</option>
                <option value='ios'>iOS</option>
              </Select>
            </FormControl>
          </Box>

          <Box mt={2} mb={2}>
              <InputLabel>Aplicativo novo</InputLabel>
              <Box mt={1} mb={1}>
                <Typography>
                  Indique se essa é a primeira versão da {storeNameByPlatform}, caso esse aplicativo nunca tenha sido publicado
                  na {storeNameByPlatform}, selecione "SIM".
                </Typography>
              </Box>
            <FormControl fullWidth>
              <Select
                native
                name="firstVersion"
                value={formData.firstVersion}
                label="Aplicativo novo"
                disabled={!formData.selectedPlatform}
                onChange={handleSelect}
              >
                <option value="">Selecione</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </Select>
            </FormControl>
          </Box>

          <Box mt={2} mb={2}>
            <InputLabel>Aplicativo exclusivo One Signal</InputLabel>
            <Box mt={1} mb={1}>
              <Typography>
                Indique se o aplicativo é exclusivo e possui integração com a plataforma One Signal.
              </Typography>
            </Box>
            <FormControl fullWidth>
              <Select
                native
                name="exclusiveApp"
                value={formData.exclusiveApp}
                label="Aplicativo exclusivo One Signal"
                disabled={!formData.selectedPlatform}
                onChange={handleSelect}
              >
                <option value="">Selecione</option>
                <option value={true}>Sim</option>
                <option value={false}>Não</option>
              </Select>
            </FormControl>
          </Box>
        </Grid>
    )
}