import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@material-ui/core';
import _ from 'lodash';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(() => ({
    title: {
        marginTop: 0
    },
    helpButton: {
        position: 'absolute',
        top: 12,
        right: 0
    },
    sectionText: {
        fontSize: 16,
        color: '#3C4252',
    },
    saveButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: 60,
        gap: 20,
    },
    buttonIcon: {
        fontSize: 16
    },
    image: {
        border: 'solid 1px #ececec',
        height: 'auto',
        maxWidth: '80%'
    },
    propertyContainer: {
        '& a': {
          color: 'black',
          textDecoration: 'none'
        }
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 0
    },
}))

export default function Step3({ formData, store, isFinished }) {
    const classes = useStyles();

    function TextProperty({ data, label, name }) {
        return (
            <div className={classes.propertyContainer}>
                <p className={classes.label}> {`${label}`}
                    <IconButton className={classes.iconButton} onClick={() => copyToClipboard(data[name])}>
                        <FileCopyIcon className={classes.buttonIcon} />
                    </IconButton>
                </p>
                <p className={classes.value}>{data[name] || '-'}</p>
            </div>
        )
    }

    function ImageProperty({ data, label, name }) {
        return (
            <div className={classes.propertyContainer}>
                <a href={data[name]} target="_blank">
                    <p className={classes.label}> {`${label}`}
                        <IconButton className={classes.buttonIcon}>
                            <LaunchIcon className={classes.icon} />
                        </IconButton>
                    </p>
                    {_.isEmpty(data[name]) ? '-' : <img className={classes.image} src={data[name]} />}
                </a>
            </div>
        )
    }

    const handleChange = (event) => {
        isFinished(event.target.checked)
    }

    return (
        <Grid container direction='column'>
            <Grid container direction='column' xs={12} spacing={2}>

                {formData.selectedPlatform === 'android' && (
                    <>
                        <Grid item>
                            <Box display='flex' flexDirection='row' alignItems='center' gridColumnGap={20}>
                                <h2 className={classes.sectionText}>
                                    Ações necessárias
                                </h2>
                            </Box>

                            <Box display='flex' flexDirection='column' gridRowGap={5}>
                                {formData.firstVersion
                                    ? <>
                                        <Typography variant='body1'>
                                            1 - Por ser a primeira versão do aplicativo na Google Play, crie o aplicativo na Google Play
                                        </Typography>
                                        <Typography variant='body1'>
                                            2 - Realize as configurações do aplicativo
                                        </Typography>
                                        <Typography variant='body1'>
                                            3 - Preencha a página detalhes do app com as informações abaixo
                                        </Typography>
                                    </>
                                    : <>
                                        <Typography variant='body1'>
                                            1 - Atualize a página detalhes do app com as informações abaixo
                                        </Typography>
                                    </>
                                }
                            </Box>
                        </Grid>

                        <Grid item>
                            <Box>
                                <h2 className={classes.sectionText}>
                                    Página detalhes do app
                                </h2>
                            </Box>
                            <Grid container direction='row' xs={12}>
                                <Grid item xs={4}>
                                    <TextProperty data={store} label="Título do aplicativo" name="title" />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextProperty data={store} label="Descrição curta" name="shortDescription" />
                                </Grid>

                                <Grid item xs={4}>
                                    <TextProperty data={store} label="Vídeo promocional" name="promotionalVideo" />
                                </Grid>                           

                                <Grid item xs={12}>
                                    <TextProperty data={store} label="Descrição" name="description" />
                                </Grid>

                                <Grid item xs={6}>
                                    <ImageProperty data={store} label="Ícone" name="iconUrl" />
                                </Grid>

                                <Grid item xs={6}>
                                    <ImageProperty data={store} label="Banner da Play Store" name="featureGraphicUrl" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                )}
                
                {formData.selectedPlatform === 'ios' && (
                    <>
                        <Grid item>
                            <Box display='flex' flexDirection='row' alignItems='center' gridColumnGap={20}>
                                <h2 className={classes.sectionText}>
                                    Ações necessárias
                                </h2>
                            </Box>

                            <Box display='flex' flexDirection='column' gridRowGap={5}>
                                {formData.firstVersion
                                    ? <>
                                        <Typography variant='body1'>
                                            O processo será executado pelo desenvolvedor
                                        </Typography>
                                    </>
                                    : <>
                                        <Typography variant='body1'>
                                            1 - Crie uma nova versão do aplicativo na App Store Connect
                                        </Typography>
                                        <Typography variant='body1'>
                                            2 - Preencha as informações abaixo
                                        </Typography>
                                    </>
                                }
                            </Box>
                        </Grid>

                        {!formData.firstVersion && (
                            <Grid item>
                                <Box>
                                    <h2 className={classes.sectionText}>
                                        Distribuição
                                    </h2>
                                </Box>
                                <Grid container direction='row' xs={12}>
                                    <Grid item xs={4}>
                                        <TextProperty data={store} label="Título do aplicativo" name="title" />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextProperty data={store} label="Descrição curta" name="shortDescription" />
                                    </Grid>                        

                                    <Grid item xs={12}>
                                        <TextProperty data={store} label="Descrição" name="description" />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}

                        
                    </>
                )}
                
                <Box display="flex" justifyContent="center" pt={2}>
                    <FormControlLabel 
                        control={<Checkbox onChange={handleChange} />}
                        label={formData.firstVersion && formData.selectedPlatform === 'ios'
                            ? "Confirmo que realizei corretamente os passos anteriores."
                            : "Confirmo que realizei corretamente os passos acima na loja de aplicativos."
                        }
                    />
                </Box>
            </Grid>          
        </Grid>
    )
}